import React from "react";
import { MdOutlineEmail, MdSupervisedUserCircle } from "react-icons/md";
import { RiSendPlaneLine } from "react-icons/ri";
import { FaRegCopy } from "react-icons/fa";
import { IoAnalytics } from "react-icons/io5";
import { RiLogoutCircleFill } from "react-icons/ri";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  return (
    <div className="container-fluid ">
      <div className="row">
        <div
          className="col-md-1 sidebar   shadow rounded-end d-flex flex-column justify-content-between"
          style={{ width: "200px" }}
        >
          <div className="nav flex-column gap-5 mt-5">
            <div className="d-flex tsidebar-icon fw-medium gap-2 sidebar-item">
              <span>
                <MdOutlineEmail className="fs-4 sidebar-icon" />
              </span>
              <div className="cursor-pointer">Email Accounts</div>
            </div>
            <div className="d-flex gap-2 fw-medium sidebar-item">
              <span>
                <RiSendPlaneLine className="fs-4 sidebar-icon" />
              </span>
              <div
                onClick={() => navigate("/campaigns")}
                className="cursor-pointer"
              >
                Campaigns
              </div>
            </div>
            <div className="d-flex gap-2 fw-medium sidebar-item">
              <span>
                <FaRegCopy className="fs-4 sidebar-icon" />
              </span>
              <div
                onClick={() => navigate("/unibox")}
                className="cursor-pointer"
              >
                Unibox
              </div>
            </div>
            <div className="d-flex gap-2 fw-medium sidebar-item">
              <span>
                <IoAnalytics className="fs-4 sidebar-icon" />
              </span>
              <div
                onClick={() => navigate("/analytics")}
                className="cursor-pointer"
              >
                Analytics
              </div>
            </div>
            <div className="d-flex gap-2 fw-medium sidebar-item">
              <span>
                <MdSupervisedUserCircle className="fs-4 sidebar-icon" />
              </span>
              <div
                onClick={() => navigate("/management")}
                className="cursor-pointer fs-6"
              >
                Lead Management
              </div>
            </div>
          </div>
          <div className="dropdown mb-4">
            <div
              className="fw-bold shadow dropdown-toggle gap-5  rounded d-flex align-items-center cursor"
              type=""
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <RiLogoutCircleFill className="fs-4 text-white " />
              <span className="mx-2 fw-medium">Logout</span>
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li
                className="dropdown-item fw-medium"
                onClick={() => navigate("/login")}
              >
                Confirm Logout
              </li>
              <li className="dropdown-item fw-medium">Cancel</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
