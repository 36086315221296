import React, { useState } from "react";
import "./Sequence.css";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { LuLayoutTemplate } from "react-icons/lu";
import { AiFillThunderbolt } from "react-icons/ai";
import { MdCleaningServices } from "react-icons/md";

function Sequence() {
  const [variants, setVariants] = useState([]);
  const [addstep, setAddstep] = useState([]);

  const handleAddVariant = () => {
    setVariants([...variants, { id: variants.length }]);
  };

  const handleAddStep = () => {
    setAddstep([...addstep, { id: addstep.length }]);
  };

  const handleDeleteVariant = (id) => {
    setVariants(variants.filter((variant) => variant.id !== id));
  };

  const handleDeleteStep = (id) => {
    setAddstep(addstep.filter((step) => step.id !== id));
  };

  return (
    <div>
      <div className="d-flex gap-3">
        <div className="col-3 sequence-tab bg-white mt-2 h-100 p-2 rounded">
          <div className="d-flex justify-content-between">
            <div className="fw-bold">Step 1</div>
            <div>
              <MdDelete />
            </div>
          </div>
          <div className="mt-2">
            <hr />
          </div>
          <div className="variant-container">
            {variants.map((variant) => (
              <div
                key={variant.id}
                className="alert alert-warning bg-white d-flex justify-content-between"
                role="alert"
              >
                <span className="badge badge-pill badge-danger text-black border">
                  A
                </span>{" "}
                <span className="font-weight-bold">Empty subject</span>
                <label className="switch">
                  <input type="checkbox" checked />
                  <span className="slider round"></span>
                </label>
                <div
                  className=""
                  onClick={() => handleDeleteVariant(variant.id)}
                >
                  <MdDelete />
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-white fw-medium"
              onClick={handleAddVariant}
            >
              <span className="me-1 text-primary">
                <FaPlus />
              </span>
              Add Variant
            </button>
          </div>
          <div className="mt-2">
            <hr />
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-bold">Step 1</div>
              <div>
                <MdDelete />
              </div>
            </div>
            <div className="mt-2">
              <hr />
            </div>
            <div className="step-container">
              {addstep.map((addstep) => (
                <div
                  key={addstep.id}
                  className="alert alert-warning bg-white d-flex justify-content-between"
                  role="alert"
                >
                  <span className="badge badge-pill badge-danger text-black border">
                    A
                  </span>
                  <span className="fw-medium">&lt;Previous email's&gt;</span>
                  <label className="switch">
                    <input type="checkbox" checked />
                    <span className="slider round"></span>
                  </label>
                  <div
                    className=""
                    onClick={() => handleDeleteStep(addstep.id)}
                  >
                    <MdDelete />
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-white fw-medium"
                onClick={handleAddStep}
              >
                <span className="me-1 text-primary">
                  <FaPlus />
                </span>{" "}
                Add Step
              </button>
            </div>
          </div>
        </div>
        <div className="col=8 bg-white p-2 mt-2 w-100 rounded">
          <div className="d-flex justify-content-between">
            <div className="fw-medium fs-5">Subject :</div>
            <div>
              <button
                className="btn btn-white fw-bold shadow  gap-5 btn-haserror text-Black rounded"
                type="button"
                aria-expanded="false"
              >
                <FaEye className="fs-6 text-primary " />
                <span className="mx-2 fw-medium">Preview</span>
              </button>
            </div>
          </div>
          <div className="mt-2">
            <hr />
          </div>
          <div class="container mt-2">
            <div class="form-group">
              <label for="exampleTextarea text-secondary">
                Start typing here.....
              </label>
              <textarea
                class="form-control no-border textarea-sequence mt-1"
                id="exampleTextarea"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="mt-2">
            <hr />
          </div>
          <div className="d-flex gap-3">
            <button type="button" className="btn btn-primary">
              Save
            </button>
            <div className="mt-1 text-secondary">
              <FaCalendarAlt />
            </div>
            <div className="mt-1 text-secondary">
              <LuLayoutTemplate />
            </div>
            <div className="mt-1 text-secondary">
              <AiFillThunderbolt />
            </div>
            <div className="mt-1 text-secondary">
              <MdCleaningServices />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sequence;
