import React from 'react'
import { PiHandsClapping } from "react-icons/pi";

function StepAnalysis() {
  return (
    <div className='fw-bold fs-5'>
   👋  Step analytics will appear here once the campaign is published
    </div>
  )
}

export default StepAnalysis
