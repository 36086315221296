import React, { useState } from "react";
import { FaCalendar, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Schedule.css";

function Schedule() {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [variants, setVariants] = useState([]);

  const handleAddVariant = () => {
    setVariants([...variants, { id: variants.length }]);
  };

  const handleDeleteVariant = (id) => {
    setVariants(variants.filter(variant => variant.id !== id));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false); // hide the calendar after selecting a date
  };

  return (
    <div>
      <div className="d-flex gap-2">
        <div className="col-3 sequence-tab bg-white mt-2 h-100 p-2 rounded">
          <div className="d-flex align-items-center w-50">
            <div className="d-flex align-items-center">
              <span className="me-2 fs-6">
                <FaCalendar />
              </span>
              <span className="fw-medium">Start</span>
            </div>
            <div
              className="border-schedule mx-2"
              style={{ borderRight: "1px solid #000", height: "24px" }}
            ></div>
            <div
              className="fs-6 fw-medium text-primary now"
              onClick={() => setShowCalendar(!showCalendar)}
              style={{ cursor: "pointer" }}
            >
              Now
            </div>
          </div>
          {showCalendar && (
            <div style={{ position: "absolute", zIndex: 1000 }}>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
              />
            </div>
          )}
          <div className="d-flex align-items-center mt-3">
            <div className="d-flex align-items-center">
              <span className="me-2 fs-6">
                <FaCalendar />
              </span>
              <span className="fw-medium">End</span>
            </div>
            <div
              className="border-scheduleend mx-3"
              style={{ borderRight: "1px solid #000", height: "24px" }}
            ></div>
            <div
              className="fs-6 fw-medium text-primary ml-5"
              onClick={() => setShowCalendar(!showCalendar)}
              style={{ cursor: "pointer" }}
            >
              No end date
            </div>
          </div>
          <div>
            <hr />
          </div>
          {variants.map((variant) => (
            <div
              key={variant.id}
              className="alert alert-warning bg-white d-flex justify-content-between mt-3"
              role="alert"
            >
              <span className="fs-5">
                <IoCalendarOutline />
              </span>
              <span className="fw-medium mt-1">New Schedule</span>
              <div className="" onClick={() => handleDeleteVariant(variant.id)} style={{ cursor: "pointer" }}>
                <MdDelete />
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-white fw-medium"
              onClick={handleAddVariant}
            >
              <span className="me-1 text-primary">
                <FaPlus />
              </span>
              Add Schedule
            </button>
          </div>
        </div>
        <div className="col-8   mt-2">
          <div className="rounded bg-white p-4 shadow">
            <div className="fw-medium fs-5">Schedule Name</div>
            <div className="mt-2">
              <input
                type="text"
                className="form-control p-3"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="New Schedule"
              />
            </div>
          </div>
          <div className="rounded bg-white p-4 mt-4 shadow">
            <div className="fw-medium fs-5">Timing</div>
            <div className="d-flex gap-5">
              <div className="mt-2">
                <div className="dropdown border rounded schedule-dropdwon">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    From
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a className="dropdown-item" href="#!">
                      8.00 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      8.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      9.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.20 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      5.10 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.00 AM
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="dropdown border rounded schedule-dropdwon">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    To
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a className="dropdown-item" href="#!">
                      8.00 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      8.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      9.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.20 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      5.10 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.00 AM
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="dropdown border rounded schedule-dropdwon">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    TimeZone
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a className="dropdown-item" href="#!">
                      8.00 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      8.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      9.30 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.20 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      5.10 AM
                    </a>
                    <a className="dropdown-item" href="#!">
                      4.00 AM
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded bg-white p-4 mt-4 shadow">
            <div className="fw-medium fs-5">Days</div>
            <div className="mt-2">
              <form className="d-flex gap-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Monday
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Tuesday
                  </label>
                </div>{" "}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Wednesday
                  </label>
                </div>{" "}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Thursday
                  </label>
                </div>{" "}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Friday
                  </label>
                </div>
              </form>
              <div className="d-flex mt-2 gap-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Saturday
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Sunday
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <button type="button" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
