import React, { useState } from "react";
import Header from "../Header/Header";
import { FaPlus } from "react-icons/fa";
import Sidebar from "../Siderbar/Sidebar";
import { IoMdTime } from "react-icons/io";
import { AiTwotoneThunderbolt } from "react-icons/ai";
import { IoPeopleSharp } from "react-icons/io5";
import { FaHandsHelping } from "react-icons/fa";
import { IoMdHeartDislike } from "react-icons/io";
import { BsEmojiGrimace } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";

const Campaign1Content = () => (
  <div>
    <div
      className="d-flex  mb-3 float-end"
      style={{
        marginRight: "20px",
      }}
    >
      <div className="">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          style={
            {
              // marginLeft: "30px",
            }
          }
        />
      </div>
      <div
        className="box rounded"
        style={{
          marginLeft: "20px",
          padding: "5px",
          border: "1px solid lightgray",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span className="fs-6 text-primary font-medium">
          <IoPeopleSharp />
        </span>
        <span className="fs-6 s">5.8k</span>
        <span className="fs-6 text-primary font-medium">
          <FaHandsHelping />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <IoMdHeartDislike />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <BsEmojiGrimace />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <FaCheckCircle />
        </span>
      </div>
    </div>
    <table className="table">
      <thead>
        <tr>
          <th>EMAIL</th>
          <th>STATUS</th>
          <th>CONTACT</th>
          <th>COMPANY</th>
          <th>WEBSITE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Data 1</td>
          <td>Data 2</td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>Data 1</td>
          <td>Data 2</td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>Data 1</td>
          <td>Data 2</td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>Data 1</td>
          <td>Data 2</td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>{" "}
        <tr>
          <td>Data 1</td>
          <td>Data 2</td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>Data 4</td>
          <td>Data 5</td>
          <td>Data 6</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Campaign2Content = () => (
  <div>
    <div
      className="d-flex  mb-3 float-end"
      style={{
        marginRight: "20px",
      }}
    >
      <div className="">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          style={
            {
              // marginLeft: "30px",
            }
          }
        />
      </div>
      <div
        className="box rounded"
        style={{
          marginLeft: "20px",
          padding: "5px",
          border: "1px solid lightgray",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <span className="fs-6 text-primary font-medium">
          <IoPeopleSharp />
        </span>
        <span className="fs-6 s">5.8k</span>
        <span className="fs-6 text-primary font-medium">
          <FaHandsHelping />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <IoMdHeartDislike />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <BsEmojiGrimace />
        </span>
        <span>0</span>
        <span className="fs-6 text-primary font-medium">
          <FaCheckCircle />
        </span>
      </div>
    </div>
    <table className="table bg-white">
      <thead>
        <tr>
          <th>
            {" "}
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              EMAIL
            </span>
          </th>

          <th>STATUS</th>
          <th>CONTACT</th>
          <th>COMPANY</th>
          <th>WEBSITE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>

          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Data 6</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Brad</td>
          <td>Outrage onstage</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Brad</td>
          <td>Outrage onstage</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Brad</td>
          <td>Outrage onstage</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Brad</td>
          <td>Outrage onstage</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Brad</td>
          <td>Outrage onstage</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text  fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
        <tr>
          <td>
            <input type="checkbox" id="select_all" className="mr-2" />
            <span
              className="text fs-6 fw-medium"
              style={{ paddingLeft: "7px" }}
            >
              test@gmail.com
            </span>
          </td>
          <td>
            <span>
              <IoMdTime className="me-2" />
            </span>
            Not yet contacted
          </td>
          <td>Data 3</td>
          <td>Data 2</td>
          <td>Data 3</td>
        </tr>
      </tbody>
    </table>
  </div>
);

function LeadManagement() {
  const [searchQuery, setSearchQuery] = useState("");
  const [campaigns, setCampaigns] = useState([
    "Campaign 1",
    "Campaign 2",
    "Campaign 1",
    "Campaign 2",
  ]);
  const [isHovered, setIsHovered] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderCampaignContent = () => {
    switch (activeTab) {
      case "Campaign 1":
        return <Campaign1Content />;
      case "Campaign 2":
        return <Campaign2Content />;
      default:
        return null;
    }
  };

  return (
    <div className="analytics-container">
      <Header className="header fixed-header" title="LEAD MANAGEMENT" />
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div
        className="analysis-container d-flex "
        style={{ marginLeft: "210px" }}
      >
        <div
          className="col-2 shadow p-4 border-right bg-white rounded "
          style={{ height: "500px" }}
        >
          <div className=" border-primary border-2 rounded">
            <div
              className="d-flex justify-content-between align-items-center"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                borderRadius: isHovered ? "8px" : "0",
                backgroundColor: isHovered ? "#f0f0f0" : "transparent",
                padding: "10px",
              }}
            >
              <div className="fs-5 fw-medium">List</div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "inherit",
                }}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                <FaPlus />
              </button>
            </div>

            <div className="mt-3">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control"
                style={{
                  border: "none",
                  borderBottom: "1px solid #ccc",
                  boxShadow: "none",
                }}
              />
            </div>

            <div className="mt-3">
              <div className="fs-5 fw-medium">All Campaigns</div>
              <div className="mt-4">
                {filteredCampaigns.map((campaign, index) => (
                  <div
                    className="campaign-item fw-medium"
                    key={index}
                    style={{
                      marginBottom: "20px",
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                    }}
                    onClick={() => handleTabClick(campaign)}
                  >
                    {campaign}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Modal */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ backgroundColor: "#2a2a2a" }}
                >
                  <h5
                    className="modal-title text-white"
                    id="staticBackdropLabel"
                  >
                    Create New List
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter the name of the list"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-10 ">
          <div
            className="d-flex"
            style={{
              marginLeft: "30px",
              cursor: "pointer",
            }}
          >
            <div className="dropdown me-3">
              <button
                className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <AiTwotoneThunderbolt className="fs-6 text-danger " />
                <span className="mx-2 fw-medium">All Status</span>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    All Status
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Paused
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    has errors
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Nocustom tracking domain
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Warm up active
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Warm up paused
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Warm up haserror
                  </a>
                </li>
              </ul>
            </div>
            <div className="me-3">
              <button
                className="btn btn-white fw-bold shadow dropdown-toggle  btn-haserror text-white rounded bg-primary"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaPlus className="fs-6" />
                <span className="mx-2 fw-medium mt-1">Add New</span>
              </button>
            </div>
          </div>
          <div >
            <div
              style={{
                marginLeft: "33px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              className="bg-white rounded p-2 mt-4 shadow"
            >
              <div className="mt-2 ">{activeTab && renderCampaignContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadManagement;
