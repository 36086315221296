import React, { useState } from "react";
import axios from "axios";
import "./UploadData.css";
import Select from "react-select";
import { Import_URL } from "../../firebase/config";
import { ensureValidToken } from "../../auth/authUtils";

const emailOptions = [
  { value: "Email", label: "Email" },
  { value: "First Name", label: "First Name" },
  { value: "Last Name", label: "Last Name" },
  { value: "Daily Limit", label: "Daily Limit" },
  { value: "IMAP Username", label: "IMAP Username" },
  { value: "IMAP Password", label: "IMAP Password" },
  { value: "IMAP Host", label: "IMAP Host" },
  { value: "IMAP Port", label: "IMAP Port" },
  { value: "SMTP Username", label: "SMTP Username" },
  { value: "SMTP Password", label: "SMTP Password" },
  { value: "SMTP Host", label: "SMTP Host" },
  { value: "SMTP Port", label: "SMTP Port" },
  { value: "Warmup Enabled", label: "Warmup Enabled" },
  { value: "Warmup Limit", label: "Warmup Limit" },
  { value: "Warmup Increment", label: "Warmup Increment" },
];

function UploadData({ data }) {
  const [uploadStatuses, setUploadStatuses] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    email: emailOptions.find((option) => option.value === "Email"),
    firstName: emailOptions.find((option) => option.value === "First Name"),
    lastName: emailOptions.find((option) => option.value === "Last Name"),
    dailylimit: emailOptions.find((option) => option.value === "Daily Limit"),
    imapUsername: emailOptions.find(
      (option) => option.value === "IMAP Username"
    ),
    imapPassword: emailOptions.find(
      (option) => option.value === "IMAP Password"
    ),
    imapHost: emailOptions.find((option) => option.value === "IMAP Host"),
    imapPort: emailOptions.find((option) => option.value === "IMAP Port"),
    smtpUsername: emailOptions.find(
      (option) => option.value === "SMTP Username"
    ),
    smtpPassword: emailOptions.find(
      (option) => option.value === "SMTP Password"
    ),
    smtpHost: emailOptions.find((option) => option.value === "SMTP Host"),
    warmupEnabled: emailOptions.find(
      (option) => option.value === "Warmup Enabled"
    ),
    warmupLimit: emailOptions.find((option) => option.value === "Warmup Limit"),
    warmupIncrement: emailOptions.find(
      (option) => option.value === "Warmup Increment"
    ),
  });

  const handleSelectChange = (field, selectedOption) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: selectedOption,
    }));
  };

  const handleUpload = async () => {
    setIsUploading(true);
    let token = await ensureValidToken();
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      console.log("No valid token or userId. Aborting upload.");
      setIsUploading(false);
      return;
    }

    const headers = {
      Token: token,
      UserId: userId,
      "Content-Type": "application/json",
    };

    if (!data || data.length === 0) {
      console.log("No data to upload.");
      setIsUploading(false);
      return;
    }

    for (let index = 0; index < data.length; index++) {
      setUploadStatuses((prevStatuses) => ({
        ...prevStatuses,
        [index]: { isLoading: true },
      }));

      const row = data[index];
      const apiPayload = {
        emailAddress: row.email,
        firstName: row.firstName,
        lastName: row.lastName,
        imapUsername: row.imapUsername || "default_imap_username",
        imapPassword: row.imapPassword || "default_imap_password",
        imapHost: row.imapHost || "imap.default.com",
        imapPort: row.imapPort || "993",
        smtpUsername: row.smtpUsername || "default_smtp_username",
        smtpPassword: row.smtpPassword || "default_smtp_password",
        smtpHost: row.smtpHost || "smtp.default.com",
        smtpPort: row.smtpPort || "465",
      };

      try {
        const response = await axios.post(Import_URL, apiPayload, { headers });

        // Check for a successful response status
        const statusMessage =
          response.data?.data?.[0]?.status ;
        const tooltipMessage = response.data?.data?.[0]?.error || "";

        setUploadStatuses((prevStatuses) => ({
          ...prevStatuses,
          [index]: {
            message: statusMessage,
            tooltip: tooltipMessage,
            isLoading: false,
          },
        }));
      } catch (error) {
        console.error("API Error:", error.response || error.message);
        setUploadStatuses((prevStatuses) => ({
          ...prevStatuses,
          [index]: {
            message: "Failed",
            tooltip:
              error.response?.data?.data?.[0]?.error ||
              error.message ||
              "Unknown Error",
            isLoading: false,
          },
        }));
      }
    }
    setIsUploading(false);
  };

  return (
    <div className="mt-5">
      <table className="table bg-black">
        <thead>
          <tr>
            <th>Column Name</th>
            <th>Select Type</th>
            <th>Samples</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            <>
              <tr>
                <th scope="row">Email</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.email}
                    onChange={(option) => handleSelectChange("email", option)}
                  />
                </td>
                <td>{data[0].email || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">First Name</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.firstName}
                    onChange={(option) =>
                      handleSelectChange("firstName", option)
                    }
                  />
                </td>
                <td>{data[0].firstName || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">Last Name</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.lastName}
                    onChange={(option) =>
                      handleSelectChange("lastName", option)
                    }
                  />
                </td>
                <td>{data[0].lastName || "No Data"}</td>
              </tr>

              <tr>
                <th scope="row">IMAP Username</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.imapUsername}
                    onChange={(option) =>
                      handleSelectChange("imapUsername", option)
                    }
                  />
                </td>
                <td>{data[0].imapUsername || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">IMAP Password</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.imapPassword}
                    onChange={(option) =>
                      handleSelectChange("imapPassword", option)
                    }
                  />
                </td>
                <td>{data[0].imapPassword || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">IMAP Host</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.imapHost}
                    onChange={(option) =>
                      handleSelectChange("imapHost", option)
                    }
                  />
                </td>
                <td>{data[0].imapHost || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">IMAP Port</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.imapPort}
                    onChange={(option) =>
                      handleSelectChange("imapPort", option)
                    }
                  />
                </td>
                <td>{data[0].imapPort || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">SMTP Username</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.smtpUsername}
                    onChange={(option) =>
                      handleSelectChange("smtpUsername", option)
                    }
                  />
                </td>
                <td>{data[0].smtpUsername || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">SMTP Password</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.smtpPassword}
                    onChange={(option) =>
                      handleSelectChange("smtpPassword", option)
                    }
                  />
                </td>
                <td>{data[0].smtpPassword || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">SMTP Host</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.smtpHost}
                    onChange={(option) =>
                      handleSelectChange("smtpHost", option)
                    }
                  />
                </td>
                <td>{data[0].smtpHost || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">Daily Limit</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.dailylimit}
                    onChange={(option) =>
                      handleSelectChange("dailylimit", option)
                    }
                  />
                </td>
                <td>{data[0].dailylimit || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">Warmup Enabled</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.warmupEnabled}
                    onChange={(option) =>
                      handleSelectChange("warmupEnabled", option)
                    }
                  />
                </td>
                <td>{data[0].warmupEnabled || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">Warmup Limit</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.warmupLimit}
                    onChange={(option) =>
                      handleSelectChange("warmupLimit", option)
                    }
                  />
                </td>
                <td>{data[0].warmupLimit || "No Data"}</td>
              </tr>
              <tr>
                <th scope="row">Warmup Increment</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues.warmupIncrement}
                    onChange={(option) =>
                      handleSelectChange("warmupIncrement", option)
                    }
                  />
                </td>
                <td>{data[0].warmupIncrement || "No Data"}</td>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan="3">No Data Available</td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-success"
        onClick={handleUpload}
        disabled={isUploading}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </button>

      <table className="table border mt-4">
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <th scope="row">{row.email}</th>
              <td>{row.firstName}</td>
              <td>{row.lastName}</td>
              <td
                title={uploadStatuses[index]?.tooltip || ""}
                style={{
                  cursor: uploadStatuses[index]?.tooltip
                    ? "pointer"
                    : "default",
                }}
              >
                {uploadStatuses[index]?.isLoading ? (
                  <div className="spinner "></div>
                ) : (
                  uploadStatuses[index]?.message || "Pending"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadData;
