import React from "react";
import "./OptionDropdown.css";

function OptionDropdown() {
  return (
    <div className="w-75">
      <div>
        <div className="fs-5 fw-bold">Custom Tags</div>
        <div className="">Tags are used to group your campaigns</div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="d-flex gap-2 justify-content-between">
              <input
                type="email"
                className="form-control input-option w-100 p-3"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Tags"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="fs-5 fw-bold mt-2">Sending pattern</div>
        <div className="">Specify how you want your emails to go</div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">Time gap between emails</div>

            <div className="d-flex  justify-content-between mt-2">
              <div className="mb-3">
                <label className="form-label text-secondary fw-medium mb-2">
                  Minimum time:
                </label>
                <div className="d-flex gap-2 align-items-center">
                  <input
                    type="text"
                    className="form-control inputoptionDropdown"
                    aria-label="Minimum time"
                    aria-describedby="input-group-right"
                  />
                  <span className="fw-medium">Minutes</span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label text-secondary fw-medium mb-2">
                  Random additional time:
                </label>
                <div className="d-flex gap-2 align-items-center">
                  <input
                    type="text"
                    className="form-control inputoptionDropdown"
                    aria-label="Random additional time"
                    aria-describedby="input-group-right"
                  />
                  <span className="fw-medium">Minutes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="d-flex  justify-content-between mt-2">
              <div className="fw-medium fs-5">Max new leads</div>
              <div className="">
                <label className="form-label text-secondary fw-medium mb-2">
                  Minimum time:
                </label>
                <div className="d-flex gap-2 align-items-center">
                  <input
                    type="text"
                    className="form-control inputoptionDropdown"
                    aria-label="Minimum time"
                    aria-describedby="input-group-right"
                  />
                  <span className="fw-medium">Per day</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="d-flex  justify-content-between mt-2">
              <div className="fw-medium fs-5">Prioritize new leads</div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label
                  className="form-check-label fw-medium"
                  htmlFor="defaultCheck1"
                >
                  Priotrize reaching out to new leads over scheduled follow-ups
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">
              Auto optimize A/Z testing{" "}
              <button type="button" className="btn btn-delivary me-2 ">
                New
              </button>
            </div>

            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary">
                When using A/Z testing, the Instantly algorithm will
                automatically <br />
                select the bestperforming variant after a certain <br />
                number of emails have been sent.
              </div>

              <div className="dropdown">
                <label className="me-2 text-secondary fw-medium">
                  {" "}
                  Choose winning metric
                </label>
                <button
                  className="btn btn-white border dropdown-toggle optn-dop"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Select
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">Provider Matching</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                Matches your lead's email provider with your mailbox provider{" "}
                <br />
                for boosted deliverability.
                <br /> (Outlook to Outlook, Google to Google, etc.)
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label
                  className="form-check-label fw-medium"
                  htmlFor="defaultCheck1"
                >
                  Enable Provider matching for deliverability boost
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">Stop campaign for company on reply</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                Stops the campaign automatically for all leads from a company{" "}
                <br /> if a reply is received from any of them.
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label
                  className="form-check-label fw-medium"
                  htmlFor="defaultCheck1"
                >
                  Enable company reply stop
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">Insert unsubscribe link header</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                Automatically adds an unsubscribe link to email headers for
                one-click <br /> unsubscription by supported email providers
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label
                  className="form-check-label fw-medium"
                  htmlFor="defaultCheck1"
                >
                  Insert unsubcriber link header
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">Allow risky emails</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                When using verification, allow emails that are marked as risky{" "}
                <br /> to be contacted; or disable BounceProtect to
                <br /> allow known risky emails to be contacted.
              </div>
              <div className="d-flex flex-column">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Enable risky emails
                  </label>
                </div>
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Disable bounceprotect
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw-medium fs-5 mt-3">CC and BCC</div>
        <div className="text-secondary fw-medium mt-1">
          Add cc and bcc recipients to your emails.
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">CC</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                Send a copy of the email to the <br /> addresses listed in the
                field
              </div>
              <div className="d-flex flex-column">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Enable risky emails
                  </label>
                </div>
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label fw-medium"
                    htmlFor="defaultCheck1"
                  >
                    Disable bounceprotect
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">CC</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
                Send a copy of the email to the <br /> addresses listed in the
                field
              </div>
              <div>
                <div className="form-group">
                  <textarea
                    className="form-control text-option"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Sepereate emails with a comma"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-100 shadow mt-2">
          <div className="p-4">
            <div className="fw-medium">BCC</div>
            <div className="d-flex  justify-content-between mt-2">
              <div className="text-secondary fw-medium">
              Send a copy of the email to certain<br/> recipients without the other recipents<br/> knowing about it
              </div>
              <div>
                <div className="form-group">
                  <textarea
                    className="form-control text-option"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Sepereate emails with a comma"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionDropdown;
