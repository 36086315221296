import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import "./Tab.css";
import { VscGraphLine } from "react-icons/vsc";

const CampaignAnalytics = () => (
  <div className="table-responsive ">
    <div className="mt-3 fs-4 fw-medium">Campaign Analytics</div>
    <table className="table  table-striped mt-4 bordered-box">
      <thead>
        <tr>
          <th className="col-4">CAMPAIGN </th>
          <th>SEQUENCE STARTED</th>
          <th>OPENED</th>
          <th>REPLIED</th>
          <th>OPPORTUNITIES</th>
          <th>ACTION</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>{" "}
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        <tr>
          <td className="campaign-cell">
            <span>Campaign </span>
            <button
              type="button"
              className="btn btn-info rounded Fw-bold text-white"
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
                width: "70px",
                fontSize: "small",
              }}
            >
              Active
            </button>
          </td>

          <td>120,517</td>
          <td>0</td>
          <td>67 | 34</td>
          <td>120</td>
          <td>
            <span className="text-info fw-bolder">
              <BsThreeDots />
            </span>
          </td>
        </tr>
        {/* Additional rows */}
      </tbody>
    </table>
  </div>
);

const AccountAnalysis = () => (
  <div className="table-responsive">
    <div className="mt-3 fs-4 fw-medium">Account Analysis</div>
    <table className="table table-striped mt-4 bordered-box">
      <thead>
        <tr>
          <th className="COL-2">SENDING ACCOUNT</th>
          <th>CONTACTED</th>
          <th>OPENED</th>
          <th>REPLIED</th>
          <th>COMBINED SCORE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>nnor@decordetech.com</td>
          <td>5</td>
          <td>0</td>
          <td>134</td>
          <td>50.0</td>
        </tr>
        <tr>
          <td>nnor@decordetech.com</td>
          <td>5</td>
          <td>0</td>
          <td>134</td>
          <td>50.0</td>
        </tr>
        <tr>
          <td>nnor@decordetech.com</td>
          <td>5</td>
          <td>0</td>
          <td>134</td>
          <td>50.0</td>
        </tr>
        <tr>
          <td>nnor@decordetech.com</td>
          <td>5</td>
          <td>0</td>
          <td>134</td>
          <td>50.0</td>
        </tr>
        <tr>
          <td>nnor@decordetech.com</td>
          <td>5</td>
          <td>0</td>
          <td>134</td>
          <td>50.0</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("campaigns");

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "campaigns" ? "active" : ""}`}
            onClick={() => handleClick("campaigns")}
          >
            <span className="me-2">
              <VscGraphLine />
            </span>
            <span className="fw-medium ">Campaign Analytics</span>
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "accounts" ? "active" : ""}`}
            onClick={() => handleClick("accounts")}
          >
            <span className="me-2">
              <VscGraphLine />
            </span>
            <span className="fw-medium">Account Analysis</span>
          </button>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane ${
            activeTab === "campaigns" ? "show active" : ""
          }`}
        >
          <CampaignAnalytics />
        </div>
        <div
          className={`tab-pane ${
            activeTab === "accounts" ? "show active" : ""
          }`}
        >
          <AccountAnalysis />
        </div>
      </div>
    </div>
  );
};

export default Tabs;
