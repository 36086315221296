import React from "react";
import "./AddAccounts.css";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { ImLibreoffice } from "react-icons/im";
import { MdDone } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import BackHeader from "../BackHeader/BackHeader";

function AddAccounts() {
  const navigate = useNavigate();

  return (
    <div>
      <BackHeader />
      <div className="cardaccunts">
        <div className="mt-">
          <div className="fs-5 fw-bold text-left">Done-For-you Email Setup</div>
          <div className="card w-100 shadow border-0 mt-5">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="fs-1 me-5">
                  <i className="">
                    <FcGoogle />
                  </i>
                </div>
                <div className="d-flex flex-column mt-3">
                  <div className="fs-6 fw-bold text-secondary text-left">
                    Google
                  </div>
                  <div className="fs-4 fw-medium">Gmail/ G-Suite</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-100 shadow border-0 mt-3">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="fs-1 me-5">
                  <i className=" text-danger">
                    <ImLibreoffice />
                  </i>
                </div>
                <div className="d-flex flex-column mt-3">
                  <div className="fs-6 fw-bold text-secondary text-left">
                    Microsoft
                  </div>
                  <div className="fs-4 fw-medium">Office 365/ Outlook</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-100 shadow border-0 mt-3">
            <div className="card-body">
              <div className="text-left">
                <div className="fw-medium ">Includes</div>
                <div>
                  <MdDone className="done" />
                  <span className="test-done"> We Set up your accounts</span>
                </div>
                <div>
                  <MdDone className="done" />
                  <span className="test-done">Automatic reconnects</span>
                </div>
                <div>
                  <MdDone className="done" />
                  <span className="test-done"> US IPs only</span>
                </div>
              </div>
              {/* <div className="d-flex align-items-center">
                <div className="fs-6 me-5 fw-medium add-card">
                  <div>
                    <span className="me-1 text-info add-card">
                      <MdDone />
                    </span>
                    We Set up your accounts
                  </div>
                  <div>
                    <span className="me-1 text-info">
                      <MdDone />
                    </span>
                    Automatic reconnects
                  </div>
                  <div>
                    <span className="me-1 text-info add-card">
                      <MdDone />
                    </span>
                    US IPs only
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="">
          <div className="fs-5 fw-bold text-left">
            Connect to new email account
          </div>
          <div className="card w-100 shadow border-0 mt-5">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="fs-1 me-5">
                  <i className="fas fa-google">
                    <FcGoogle />
                  </i>
                </div>
                <div className="d-flex flex-column mt-3">
                  <div className="fs-6 fw-bold text-secondary text-left">
                    Google
                  </div>
                  <div className="fs-4 fw-medium">Gmail/ G-Suite</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-100 shadow border-0 mt-3">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="fs-1 me-5">
                  <i className="fas fa-libreoffice text-danger">
                    <ImLibreoffice />
                  </i>
                </div>
                <div className="d-flex flex-column mt-3">
                  <div className="fs-6 fw-bold text-secondary text-left">
                    Microsoft
                  </div>
                  <div className="fs-4 fw-medium">Office 365/ Outlook</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-100 shadow border-0 mt-3">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="fs-1 me-5">
                  <i className="text-secondary">
                    <MdOutlineMail />
                  </i>
                </div>
                <div
                  className="d-flex flex-column mt-3"
                  onClick={() => navigate("/anyprovider")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="fs-6 fw-bold anyprovider text-left">
                    Any Provide
                  </div>
                  <div className="fs-4 fw-medium">IMAP/ SMTP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAccounts;
