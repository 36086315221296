import React from "react";
import Chart from "react-apexcharts";

const AreaChart = () => {
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "2023-01-01T00:00:00.000Z",
        "2023-01-02T00:00:00.000Z",
        "2023-01-03T00:00:00.000Z",
        "2023-01-04T00:00:00.000Z",
        "2023-01-05T00:00:00.000Z",
        "2023-01-06T00:00:00.000Z",
        "2023-01-07T00:00:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const series = [
    {
      name: "Data Series 1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
  ];

  return (
    <div style={{ width: "1248px" }}>
      {" "}
      {/* Set the width of the container div here */}
      <Chart options={options} series={series} type="area" height={310} />
    </div>
  );
};

export default AreaChart;
