import React, { useState } from "react";
import "./AnalyticTab.css";
import AnalyticCard from "../AnalyticCard/AnalyticCard";
import StepAnalysis from "../StepAnalyis/StepAnalysis";
import ActivityTab from "../ActivityTab/ActivityTab";
import { CiShare2 } from "react-icons/ci";

function AnalyticTab() {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="test bg-white p-5 h-100">
      <div className="d-flex justify-content-between ">
        <div className="d-flex align-items-center">
          <span className="me-3">Status:</span>
          <button type="button" className="btn btn-dark me-2">
            Draft
          </button>
          <span className="fw-medium">100%</span>
        </div>
        <div className="buttoncamana">
          <button type="button" className="btn btn-primary me-2">
            <span className="me-1 fw-medium">
              <CiShare2 />
            </span>
            Share
          </button>
          <button
            className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="mx-2 fw-medium">Last 4 weeks</span>
          </button>
        </div>
      </div>
      <div className="d-flex gap-3 mt-2 ">
        <AnalyticCard cardtittle="Sequence started" rating="5" />
        <AnalyticCard cardtittle="Open rate" rating="-" />
        <AnalyticCard cardtittle="Click rate" rating="-|-" />
        <AnalyticCard cardtittle="Reply rate" rating="5" />
        <AnalyticCard cardtittle="Opportunities" rating="5" />
      </div>
      <div className="card no-data border p-3 mt-4 card-analytic  mt-3 d-flex justify-content-center align-items-center">
        <div className="card-body d-flex flex-column align-items-center justify-content-center">
          No data available for specified time
        </div>
      </div>
      <div className="card no-data p-3 mt-4 border mt-3">
        <div>
          <div className="d-flex">
            <div
              className={`d-flex me-2 fw-medium tab ${
                activeTab === "StepAnalysis" ? "tab-active" : ""
              }`}
              onClick={() => handleTabClick("StepAnalysis")}
            >
              Step Analytics
            </div>
            <div
              className={`d-flex me-2 fw-medium tab ${
                activeTab === "Activity" ? "tab-active" : ""
              }`}
              onClick={() => handleTabClick("Activity")}
            >
              Activity
            </div>
          </div>
          <hr />
        </div>

        <div className="mt-4">
          {activeTab === "StepAnalysis" && <StepAnalysis />}
          {activeTab === "Activity" && <ActivityTab />}
        </div>
      </div>
    </div>
  );
}

export default AnalyticTab;
