import React from "react";
import { useNavigate } from "react-router-dom";

function Lead() {
  const navigate = useNavigate();
  return (
    <div className="bg-white p-3  ">
      <div className="d-flex justify-content-center flex-column align-items-center mt-4">
        <img
          alt=""
          className="logo"
          src="https://static.wixstatic.com/media/4d38c6_51d5cfb779554e699505a399a48871c5~mv2.png/v1/fill/w_443,h_341,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/pixeltrue-plan-1%201.png"
        />
        <span className="mt-3 fw-medium fs-4">
          👋 Add some leads to get started
        </span>
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-primary shadow fw-medium me-2 border"
            onClick={() => navigate("/uploadlead")}
          >
            Add leads
          </button>
        </div>
      </div>
    </div>
  );
}

export default Lead;
