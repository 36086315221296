import React from "react";

function ActivityTab() {
  return (
    <div className="fw-bold fs-5">
      👋 Your activity stream will appear here once the campaign is published
    </div>
  );
}

export default ActivityTab;
