import React from "react";
import BackCampaigns from "../BackCampaigns/BackCampaigns";
import Sidebar from "../Siderbar/Sidebar";
import CampaignsTab from "../CampaignsTab/CampaignsTab";
import "./MyCampaigns.css";

function MyCampaigns() {
  return (
    <div className="">
  
        <BackCampaigns />
        <Sidebar />
     
      <div className="mycampaign">
        <CampaignsTab />
      </div>
    </div>
  );
}

export default MyCampaigns;
