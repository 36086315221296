// Firebase configuration and initialization removed
// import firebase from 'firebase/app';
// import 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "YOUR_API_KEY",
//   authDomain: "YOUR_AUTH_DOMAIN",
//   projectId: "YOUR_PROJECT_ID",
//   storageBucket: "YOUR_STORAGE_BUCKET",
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//   appId: "YOUR_APP_ID",
// };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export default firebase;

// Setting up base URL for the API
export const API_BASE_URL = "https://webic.online/emailpro";
export const SIGNUP_URL = `${API_BASE_URL}/user/signup`;
export const TOKEN_URL = `${API_BASE_URL}/tokenizer/generate`;
export const LOGIN_URL = `${API_BASE_URL}/user/signin`;
export const List_URL = `${API_BASE_URL}/eaccounts/list`;
export const Import_URL = `${API_BASE_URL}/eaccounts/importRow`;
export const Delete_URL = `${API_BASE_URL}/eaccounts/remove`;
export const View_URL = `${API_BASE_URL}/eaccounts/settings/view`;
export const Edit_URL = `${API_BASE_URL}/eaccounts/settings/edit`;
export const Campaigns_URL = `${API_BASE_URL}/campaigns/list`;
export const Addcampaigns_URL = `${API_BASE_URL}/campaigns/addnew`;
export const Deletecampaigns_URL = `${API_BASE_URL}/campaigns/delete`;
export const Editcampaigns_URL = `${API_BASE_URL}/campaigns/rename`;
export const Taglist_URL = `${API_BASE_URL}/tags/list`;
export const CreateTag_URL = `${API_BASE_URL}/tags/create`;
export const Remove_URL = `${API_BASE_URL}/eaccounts/removeall`;
export const Paused_URL = `${API_BASE_URL}/eaccounts/unpauseall`;
export const Removselected_URL = `${API_BASE_URL}/campaigns/removeall`;
export const Pause_URL = `${API_BASE_URL}/campaigns/pausenow`;
export default API_BASE_URL;
