import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { FaPlus, FaUser, FaFire, FaTrash, FaClock } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ensureValidToken } from "../../auth/authUtils";

import axios from "axios";
import { MdConnectingAirports } from "react-icons/md";
import {
  List_URL,
  Delete_URL,
  View_URL,
  Edit_URL,
  CreateTag_URL,
} from "../../firebase/config";
import "./EmailPage.css";
import Sidebar from "../Siderbar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import OptionMenu from "../Menu/OptionMenu";

const EmailPage = () => {
  const navigate = useNavigate();
  const [emailAccounts, setEmailAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState(["T1"]); // Example tag list
  const [newTag, setNewTag] = useState({ label: "", description: "" });
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false); // State for "Select All"
  const [selectedEmails, setSelectedEmails] = useState([]); // State to store selected email accounts

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch email accounts data
  const fetchData = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(List_URL, {}, { headers });

      if (
        response.data.success &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setEmailAccounts(response.data.data[0].emailAccountsData);
      } else {
        throw new Error("No email accounts data available.");
      }
    } catch (error) {
      console.error(
        "Data fetch error:",
        error.response ? error.response.data : error.message
      );
      alert(
        "Failed to load email accounts. Please check your token and try again."
      );
    }
  };

  // Toggle selection of all checkboxes
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedEmails(emailAccounts.map((account) => account.accountId));
    } else {
      setSelectedEmails([]);
    }
  };

  // Toggle selection of individual checkbox
  const handleCheckboxChange = (accountId) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(accountId)
        ? prevSelected.filter((id) => id !== accountId)
        : [...prevSelected, accountId]
    );
  };

  // Navigate to add new accounts page
  const handleAddNewClick = () => {
    navigate("/add-accounts");
  };

  // Toggle tag creation modal
  const toggleCreateTagModal = () => {
    setShowCreateTagModal(!showCreateTagModal);
  };

  // Delete an email account
  const handleDelete = async (accountId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this account?"
    );
    if (!confirmDelete) return;

    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(Delete_URL, { accountId }, { headers });

      if (response.data.success) {
        alert("Account deleted successfully.");
        setEmailAccounts((prevAccounts) =>
          prevAccounts.filter((account) => account.accountId !== accountId)
        );
      } else {
        alert("Failed to delete account on server.");
      }
    } catch (error) {
      console.error(
        "Delete error:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to delete account. Please try again.");
    }
  };

  const loadAccountSettings = async (accountId) => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(View_URL, { accountId }, { headers });

      if (response.data.success && response.data.data[0].settingsData) {
        setSelectedAccount(response.data.data[0].settingsData[0]);
      }
    } catch (error) {
      console.error("Error loading account settings:", error.message);
      alert("Failed to load account settings.");
    }
  };

  // Handle changes in account settings input
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSelectedAccount((prevAccount) => ({ ...prevAccount, [id]: value }));
  };

  // Save changes to account settings
  const handleSave = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(Edit_URL, selectedAccount, { headers });

      if (response.data.success) {
        alert("Changes saved successfully.");
      } else {
        alert("Failed to save changes.");
      }
    } catch (error) {
      console.error("Save error:", error.message);
      alert("Failed to save changes. Please try again.");
    }
  };

  return (
    <div>
      <Header title="EMAIL ACCOUNTS" />
      <Sidebar />
      <div className="email-container" style={{ marginLeft: "230px" }}>
        <div className="d-flex justify-content-end gap-4 align-items-center mt-5">
          {selectAll && (
            <div className="d-flex gap-3 align-items-center">
              <OptionMenu
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                fetchData={fetchData}
              />
            </div>
          )}
          <button
            className="btn btn-primary float-end  mt-4 shadow rounded d-flex align-items-center"
            onClick={handleAddNewClick}
          >
            <FaPlus className="fs-6" />
            <span className="fw-medium">Add New</span>
          </button>
        </div>

        <div className="w-100 mt-1 p-3 table-bg rounded">
          <div className="input-group search" style={{ width: "200px" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
          </div>

          <table className="table mt-3 table-striped table-email">
            <thead className="bg-secondary">
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    id="select_all"
                    className="mr-2"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <span className="text fs-6 fw-medium">NAME</span>
                </th>
                <th scope="col" className="fs-6 fw-medium">
                  EMAIL SENT
                </th>
                <th scope="col" className="fs-6 fw-medium"></th>
              </tr>
            </thead>
            <tbody>
              {emailAccounts.map((account) => (
                <tr key={account.accountId} className="text-left">
                  <td>
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={selectedEmails.includes(account.accountId)}
                      onChange={() => handleCheckboxChange(account.accountId)}
                    />
                    <span
                      className="pl-2 text fs-6"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      onClick={() => loadAccountSettings(account.accountId)}
                    >
                      {account.emailAddress}
                    </span>
                  </td>
                  <td>{account.emailsSentCount}</td>
                  <td className=" gap-5">
                    <MdConnectingAirports
                      className="cursor-pointer"
                      onClick={handleAddNewClick}
                    />
                    <FaTrash
                      className="text-danger cursor-pointer"
                      onClick={() => handleDelete(account.accountId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Email Settings</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container my-4 p-4 border rounded">
            <div className="d-flex align-items-center mb-3">
              <FaUser className="me-2" style={{ color: "#6c757d" }} />
              <h5 className="mb-0">Sender Name</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={selectedAccount ? selectedAccount.firstName : ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={selectedAccount ? selectedAccount.lastName : ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="d-flex align-items-center mt-4 mb-3">
              <FaClock className="me-2" style={{ color: "#6c757d" }} />
              <h5 className="mb-0">Campaign Settings</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="dailyCampaignLimit" className="form-label">
                  Daily Limit
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="dailyCampaignLimit"
                  value={
                    selectedAccount ? selectedAccount.dailyCampaignLimit : ""
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="minimumWaitTime" className="form-label">
                  Wait Time (minutes)
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minimumWaitTime"
                  value={selectedAccount ? selectedAccount.minimumWaitTime : ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary fw-medium"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPage;
