import React from "react";
import { MdTimelapse } from "react-icons/md";
import { GoDash } from "react-icons/go";

function AnalyticCard({ cardtittle, rating }) {
  return (
    <div>
      <div className="card  card-analytic border mt-3 d-flex justify-content-center align-items-center">
        <div className="card-body  d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <div className="fs-6 fw-bold text-secondary text-center d-flex align-items-center gap-2">
              <div>{cardtittle}</div>
              <MdTimelapse />
            </div>
            <div className="fs-4 fw-medium text-center">{rating}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticCard;
