import React, { useState } from "react";
import axios from "axios";
import { SIGNUP_URL } from "../../firebase/config";
import { ensureValidToken, getFreshToken } from "../../auth/authUtils";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      let token = await ensureValidToken();
      console.log("Retrieved Token:", token);

      if (!token) {
        setErrorMessage("Failed to retrieve a valid token. Please try again.");
        return;
      }

      const headers = {
        Token: token,
        "Content-Type": "application/json",
      };
      const body = {
        firstName,
        lastName,
        emailAddress: email,
        password,
        confirmPassword,
      };

      const response = await axios.post(SIGNUP_URL, body, { headers });
      console.log("Server Response:", response);

      if (response.data.success) {
        setSuccessMessage("Sign up successful! Redirecting to login...");
        setErrorMessage("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");

        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else if (
        response.data &&
        response.data.message &&
        response.data.message.includes("token")
      ) {
        token = await getFreshToken();
        if (token) {
          localStorage.setItem("token", token);
          handleSignUp();
        } else {
          setErrorMessage(
            "Token issue. Failed to regenerate token. Please try again later."
          );
        }
      } else {
        setErrorMessage(
          response.data.message || "Sign up failed. Please try again."
        );
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error during sign up:", error);
      if (error.response) {
        console.log("Error Response Data:", error.response.data); 
        setErrorMessage(
          error.response.data.message || "Sign up failed. Please try again."
        );
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        setErrorMessage("No response received from server. Please try again.");
      } else {
        console.error("An error occurred:", error.message);
        setErrorMessage("An error occurred. Please try again.");
      }
      setSuccessMessage("");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="fw-bold text-center text-black fs-5 gap-4">
          Email System
        </div>
        <div className="card shadow mt-4 main-login">
          <div className="card-body d-flex flex-column card-login rounded justify-content-center">
            <h3 className="card-title text-center text-white mb-4">Sign Up</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="firstName"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="lastName"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="email"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="confirmPassword"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSignUp}
                >
                  Sign Up
                </button>
              </div>
              {errorMessage && (
                <div className="alert alert-danger mt-3">{errorMessage}</div>
              )}
              {successMessage && (
                <div className="alert alert-success mt-3">{successMessage}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
