import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ensureValidToken, getFreshToken } from "../../auth/authUtils";
import { LOGIN_URL } from "../../firebase/config";
import { FaUser, FaLock, FaArrowRight } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import "./Login.css";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleLogin = async () => {
    try {
      let token = await ensureValidToken();

      if (!token) {
        // Optionally, handle token retrieval failure silently or notify the user differently
        return;
      }

      const headers = {
        Token: token,
        "Content-Type": "application/json",
      };
      const body = {
        username: email,
        password: password,
      };

      console.log("Login payload:", body);

      const response = await axios.post(LOGIN_URL, body, { headers });
      console.log("Server Response:", response);

      if (response.data.success) {
        setSuccessMessage("Logged in successfully!");
        setEmail("");
        setPassword("");

        // Store user data in local storage
        const userData = response.data.data[0].userData[0];
        localStorage.setItem("userId", userData.userId);
        localStorage.setItem("userEmail", userData.emailAddress); // Optional

        if (onLogin) onLogin(email, password);

        setTimeout(() => {
          navigate("/email");
        }, 2000);
      } else if (
        response.data &&
        response.data.message &&
        response.data.message.includes("token")
      ) {
        token = await getFreshToken();
        if (token) {
          localStorage.setItem("token", token);
          handleLogin();
        } else {
          // Optionally, handle token regeneration failure silently or notify the user differently
        }
      } else {
        // Optionally, handle other failure cases silently or notify the user differently
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error during login:", error);

      setSuccessMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="fw-bold text-center text-black fs-5 gap-4">
          <span className="fs-1">
            <MdMarkEmailUnread />
          </span>
          Email System
        </div>
        <div className="card shadow mt-4 main-login">
          <div className="card-body d-flex flex-column card-login rounded justify-content-center">
            <h3 className="card-title text-center text-white mb-4">Login</h3>

            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="email"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  <FaUser /> Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="mb-3 d-flex flex-column">
                <label
                  htmlFor="password"
                  className="form-label text-left text-white"
                  style={{ color: "#475569", marginBottom: "5px" }}
                >
                  <FaLock /> Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </div>

              <div className="d-flex gap-3">
                <button
                  type="submit"
                  className="btn btn-sm fw-medium mt-3"
                  style={{
                    width: "100px",
                    backgroundColor: "#22c55e",
                    borderColor: "#22c55e",
                    color: "white",
                  }}
                >
                  Login
                  <span>
                    <FaArrowRight />
                  </span>
                </button>

                <div className="fs-6 mt-3 text-white">Forgot Password?</div>
              </div>

              <div className="fs-6 mt-4  text-center text-white">
                Do you have an account?
                <span
                  style={{ color: "#9ca3af", cursor: "pointer" }}
                  className="fw-bold"
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  SignUp
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header header-signup">
                <h5 className="modal-title">Forgot Password</h5>
              </div>
              <div className="modal-body">
                <p>Please enter your email address to reset your password.</p>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
