import React from "react";
import { MdTimelapse } from "react-icons/md";
import { GoDash } from "react-icons/go";
import "./AnalyticsRate.css";

function AnalyticsRate({ title, rate }) {
  return (
    <div className="card card-analytic border-0 mt-3 d-flex justify-content-center align-items-center">
      <div className="card-body d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <div className="fs-6 fw-bold text-secondary text-center d-flex align-items-center gap-2">
            <GoDash className="Warning fs-3" />
            <div>{title}</div>
            <MdTimelapse />
          </div>
          <div className="fs-4 fw-medium text-center">{rate}</div>
        </div>
      </div>
    </div>
  );
}

export default AnalyticsRate;
