import axios from "axios";
import { TOKEN_URL } from "../firebase/config"; // Ensure this path is correct

const authKey = "MTE3NWJjNzkyYWNjMGMzNGMzZGIyMjFmYzdhNmI3N2E6ZW1haWxwcm9AdjE=";

export const getAuthToken = async () => {
  const token = localStorage.getItem("token");
  const expiry = localStorage.getItem("tokenExpiry");
  const currentTime = new Date().getTime();

  // Use cached token if it hasn't expired
  if (token && expiry && currentTime < parseInt(expiry, 10)) {
    return token;
  }

  // Fetch a new token if no valid cached token is found
  return await getFreshToken();
};

export const getFreshToken = async () => {
  try {
    const response = await axios.post(
      TOKEN_URL,
      {},
      {
        headers: {
          Authkey: `Bearer ${authKey}`,
          "Content-Type": "application/json",
        },
      }
    );

    // Check if token generation was successful
    if (response.data && response.data.success) {
      const { token, validTill } = response.data.data[0];
      const expiryTime = new Date(validTill).getTime();
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiry", expiryTime.toString());
      return token;
    } else {
      console.warn("Token generation failed.");
      return null;
    }
  } catch (error) {
    console.error("Token request error:", error.message);
    return null;
  }
};

export const ensureValidToken = async () => {
  let token = await getAuthToken();
  if (!token) {
    token = await getFreshToken(); // Retry fetching a fresh token
    if (!token) {
      throw new Error("Failed to retrieve a valid token");
    }
  }
  return token;
};

// Function to initialize and get a valid token
export const initializeAuthToken = async () => {
  return await ensureValidToken();
};
