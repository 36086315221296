import React, { useState } from "react";
import "./Option.css";
import { MdSaveAlt } from "react-icons/md";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import OptionDropdown from "../OptionDropdown/OptionDropdown";

function Option() {
  const [showDropdown, setShowDropdown] = useState(false); // state variable to control the visibility

  const handleAddClick = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown); // toggle the visibility
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="card w-75 shadow mt-2">
        <div className="p-4 ">
          <div className="d-flex gap-2">
            <div>
              <div className="fw-medium fs-5">Accounts to use</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Select one or more accounts to send emails from
              </div>
            </div>
            <div className="input-group mb-3">
              <select className="form-select" id="inputGroupSelect01">
                <option selected>Select...</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="card w-75 shadow mt-2">
        <div className="p-4 ">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">Stop sending emails on reply</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Stop sending emails to a lead if a response has been received
                from
              </div>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <label
                className="form-check-label fw-medium"
                htmlFor="defaultCheck1"
              >
                Stop on auto-reply
              </label>
            </div>
            <div
              className="btn-group btn-option"
              role="group"
              aria-label="Basic example"
            >
              <button type="button" className="btn btn-secondary">
                Disable
              </button>
              <button type="button" className="btn btn-success">
                Enable
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card w-75 shadow mt-2">
        <div className="p-4 ">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">Open Tracking</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Track email opens
              </div>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <label
                className="form-check-label fw-medium"
                htmlFor="defaultCheck1"
              >
                Link Tracking
              </label>
            </div>
            <div
              className="btn-group btn-option"
              role="group"
              aria-label="Basic example"
            >
              <button type="button" className="btn btn-secondary">
                Disable
              </button>
              <button type="button" className="btn btn-success">
                Enable
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card w-75 shadow mt-2">
        <div className="p-4 ">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">
                Delivery Optimization{" "}
                <button type="button" className="btn btn-delivary me-2 ">
                  Recommended
                </button>
              </div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Disables open tracking
              </div>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <label
                className="form-check-label fw-medium"
                htmlFor="defaultCheck1"
              >
                Send emails as text-only
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card w-75 shadow mt-2">
        <div className="p-4 ">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">Daily Limit</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Max number of emails to send per day for this campaign
              </div>
            </div>
            <input
              type="email"
              className="form-control input-option"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder=""
            />
          </div>
        </div>
      </div>
      {showDropdown && <OptionDropdown />}
      <div className="mt-3">
        <button
          type="button"
          className="btn btn-white border bg-white fw-bold"
          onClick={handleAddClick}
        >
          Hide advance options
        </button>
      </div>
      <div className="d-flex gap-2 mb-2 mt-3">
        <button type="button" className="btn btn-primary">
          <span className="me-1">
            <HiMiniRocketLaunch />
          </span>
          Launch
        </button>
        <button type="button" className="btn btn-secondary">
          <span className="me-1">
            <MdSaveAlt />
          </span>
          Save
        </button>
      </div>
    </div>
  );
}

export default Option;
