import React from "react";
import { SiMinutemailer } from "react-icons/si";
import "./Header.css";

const Header = ({ title }) => {
  return (
    <header className=" shadow-sm text-white py-3 px-4 header">
      <div className="d-flex justify-content-between">
        <div className="fs-6 fw-bold d-flex align-items-center gap-2 text-white">
          <span className="fs-3">
            <SiMinutemailer />
          </span>
          {title}
        </div>

        <div className="dropdown">
          <button
            className="btn btn-header fw-bold shadow dropdown-toggle rounded text-white"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            My Organization
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">
              Action 1
            </a>
            <a className="dropdown-item" href="#">
              Action 2
            </a>
            <a className="dropdown-item" href="#">
              Action 3
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
