import React, { useState } from "react";
import "./Analytics.css";
import Sidebar from "../Siderbar/Sidebar";
import Header from "../Header/Header";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdPlayArrow } from "react-icons/md";
import AnalyticsRate from "../AnalyticsRate/AnalyticsRate";
import AreaChart from "../AreaChart/AreaChart";
import Tab from "../AnalyticsTab/Tab";

function DropdownWithSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownItems, setDropdownItems] = useState([
    "All Status",
    "Paused",
    "has errors",
    "Nocustom tracking domain",
    "Warm up active",
    "Warm up paused",
    "Warm up haserror",
  ]);

  const filteredItems = dropdownItems.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredItems.map((item, index) => (
        <div key={index}>
          <a className="dropdown-item" href="#">
            {item}
          </a>
        </div>
      ))}
    </div>
  );
}

function Analytics() {
  return (
    <div className="analytics-container">
      <Header className="header fixed-header " title="ANALYTICS" />
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="analysis-container">
        <div className="Analysis mt-5">
          <div className=" head-buttoms d-flex flex-column gap-2">
            <div className="d-flex gap-2">
              <button type="button" className="btn Share shadow fw-medium">
                <IoShareSocialOutline className="fw-medium" /> Share
              </button>
              <div className="dropdown">
                <button
                  className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded Active"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <MdPlayArrow className="fs-3 text-primary" />
                  <span className="mx-1 mt-2 fw-medium">Active</span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <DropdownWithSearch />
                  </li>
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded Active"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <MdPlayArrow className="fs-3 text-primary" />
                  <span className="mx-1 mt-2 fw-medium">Last 4 Weeks</span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <DropdownWithSearch />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="card-rate  d-flex gap-2">
              <AnalyticsRate title="Total sent" rate="206.6K" />
              <AnalyticsRate title="Open rate" rate="0.0%" />
              <AnalyticsRate title="Click rate" rate="0.0%" />
              <AnalyticsRate title="Reply rate" rate="0.01%" />
              <AnalyticsRate title="Opportunities" rate="29" />
            </div>
          </div>
          <div className="mt-3 chart-analytics rounded">
            <AreaChart />
          </div>
          <div className="mt-4 tab-analytics rounded">
            <Tab />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
