import React, { useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Siderbar/Sidebar";
import "./UniBox.css";
import { FaPlus } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillThunderbolt } from "react-icons/ai";
import { BiSolidErrorCircle } from "react-icons/bi";

function UniBox() {
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [secondaryDropdownOpen, setSecondaryDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const dropdownItems = [
    { name: "Lead", icon: AiFillThunderbolt },
    "Interested",
    "Meeting booked",
    "Meeting completed",
    "Won",
    { name: "More", icon: FaPlus },
    // Add more items as needed
  ];

  const secondaryDropdownItems = [
    { name: "Out of office", icon: AiFillThunderbolt },
    { name: "Wrong Person", icon: AiFillThunderbolt },
    { name: "Not interested", icon: AiFillThunderbolt },
    { name: "Lost", icon: AiFillThunderbolt },
    { name: "Create a new Label", icon: FaPlus },
    // Add more items as needed
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSearchQuery(""); // Clear search query when dropdown opens/closes
  };

  const toggleSecondaryDropdown = () => {
    setSecondaryDropdownOpen(!secondaryDropdownOpen);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCampaignSelect = (campaign) => {
    setSelectedCampaign(campaign);
  };

  const filteredItems = dropdownItems.filter((item) => {
    if (typeof item === "string") {
      return item.toLowerCase().includes(searchQuery.toLowerCase());
    } else {
      return item.name.toLowerCase().includes(searchQuery.toLowerCase());
    }
  });

  const [campaigns] = useState([
    "Test",
    "Bounce",
    "Test",
    "Test",
    "Test",
    "Test",
    "Warm up haserror",
  ]);
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);

  const handleCampaignSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filtered = campaigns.filter((campaign) =>
      campaign.toLowerCase().includes(searchQuery)
    );
    setFilteredCampaigns(filtered);
  };

  return (
    <div>
      <Header title="UNIBOX" />
      <Sidebar />

      <div className="unibox-container gap-2 d-flex" style={{ marginLeft: "230px" }}>
        <div
          className="col-3 shadow-sm p-4 border-right bg-white rounded"
          style={{ height: "600px" }}
        >
          <div className="dropdown btn-status rounded">
            <button
              className="btn btn dropdown-toggle fw-medium"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            >
              Status
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu show full-width-dropdown">
                {" "}
                {/* Added 'full-width-dropdown' class */}
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control mb-2"
                  autoFocus
                />
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <div key={index}>
                      {typeof item === "string" ? (
                        <a className="dropdown-item" href="#">
                          {item}
                        </a>
                      ) : (
                        <div className="dropdown-item">
                          <span onClick={toggleSecondaryDropdown}>
                            {item.name}
                          </span>
                          {item.name === "More" && secondaryDropdownOpen && (
                            <div className="dropdown-menu show mt-2">
                              {secondaryDropdownItems.map(
                                (subItem, subIndex) => (
                                  <a
                                    key={subIndex}
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      if (
                                        subItem.name === "Create a new Label"
                                      ) {
                                        handleShowModal();
                                      }
                                      // Add more actions as needed
                                    }}
                                  >
                                    {subItem.icon && (
                                      <subItem.icon
                                        className={
                                          subItem.name === "Lost"
                                            ? "icon-red"
                                            : subItem.name === "Not interested"
                                            ? "icon-orange"
                                            : subItem.name === "Wrong Person"
                                            ? "icon-ash"
                                            : subItem.name === "Out of office"
                                            ? "icon-Lightblue"
                                            : ""
                                        }
                                      />
                                    )}{" "}
                                    {subItem.name}
                                  </a>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <span className="dropdown-item">No results found</span>
                )}
              </div>
            )}
          </div>

          <div className="dropdown btn-status mt-4 rounded">
            <button
              className="btn fw-bold btn-status dropdown-toggle gap-5 text-Black rounded"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="mx-2 fw-medium">All Campaigns</span>
            </button>
            <div
              className="dropdown-menu show full-width-dropdown"
              style={{ padding: "10px" }}
            >
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Search campaigns..."
                onChange={handleCampaignSearch}
              />
              <ul className="list-unstyled mb-0">
                {filteredCampaigns.map((campaign, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleCampaignSelect(campaign)}
                    >
                      {campaign}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="dropdown mt-4 btn-status rounded">
            <button
              className="btn fw-bold dropdown-toggle gap-5 text-Black rounded"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="mx-2 fw-medium">All Inbox</span>
            </button>
            <div
              className="dropdown-menu show full-width-dropdown"
              style={{ padding: "10px" }}
            >
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Search campaigns..."
                onChange={handleCampaignSearch}
              />
              <ul className="list-unstyled mb-0">
                {filteredCampaigns.map((campaign, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleCampaignSelect(campaign)}
                    >
                      {campaign}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-3 shadow-sm bg-white rounded ">
          <div className="d-flex justify-content-around mt-4">
            <div className="hover-effect fw-medium">Primary</div>
            <div className="hover-effect fw-medium">Others </div>
          </div>

          <div className="mt-4 search-tab">
            <input
              type="text"
              placeholder="Search..."
              className="mb-2  rounded searchp"
              autoFocus
            />
          </div>

          {selectedCampaign && (
            <div className="d-flex align-items-center flex-column justify-content-center mt-5">
              <span>
                <BiSolidErrorCircle className="fs-1 rounded" />
              </span>

              <h1 className="fs-5 mt-4">
                No emails found for {selectedCampaign}
              </h1>
              <button
                type="button"
                className="btn btn-secondary fw-medium btn-load  mt-4"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#2996da" }}
              >
                <h5 className="modal-title text-white" id="staticBackdropLabel">
                  Create New List
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="col">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Label*"
                  />
                </div>
                <div className="col mt-2">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Description"
                  />
                </div>
                <div className="col mt-2">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Select interest status*"
                  />
                </div>
                <div className="fw-medium mt-2 popup-description">
                  Positive / Negative status can't be changed once the status is
                  created
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-primary">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UniBox;
