import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function BackHeader() {
  const navigate = useNavigate();
  return (
    <header
      className="back-header shadow text-white py-3 px-4 "
      style={{ backgroundColor: "#2a2a2a" }}
    >
      <div className="d-flex justify-content-between">
        <div className="fs-6 fw-medium d-flex align-items-center  text-black">
          <span className=" me-1 text-white">
            <IoMdArrowBack onClick={() => navigate("/emailpage")} />
          </span>
          <div className="mt-1 fs-5 text-white">Back</div>
        </div>
      </div>
    </header>
  );
}

export default BackHeader;
