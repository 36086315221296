import React, { useState } from "react";
import "./CampaignsTab.css";
import AnalyticTab from "../AnalyticTab/AnalyticTab";
import Lead from "../LeadCampaingsTab/Lead";
import Sequence from "../Sequence/Sequence";
import Schedule from "../Schedule/Schedule";
import Option from "../Option/Option";

function CampaignsTab() {
  const [activeTab, setActiveTab] = useState("Analytics");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="d-flex gap-4 bg-white p-3 h-100">
        <div
          className={`fs-6 text-secondary fw-medium hover-underline ${
            activeTab === "Analytics" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Analytics")}
        >
          Analytics
        </div>
        <div
          className={`fs-6 text-secondary fw-medium hover-underline ${
            activeTab === "Leads" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Leads")}
        >
          Leads
        </div>
        <div
          className={`fs-6 text-secondary fw-medium hover-underline ${
            activeTab === "Sequence" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Sequence")}
        >
          Sequence
        </div>
        <div
          className={`fs-6 text-secondary fw-medium hover-underline ${
            activeTab === "Schedule" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Schedule")}
        >
          Schedule
        </div>
        <div
          className={`fs-6 text-secondary fw-medium hover-underline ${
            activeTab === "Options" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Options")}
        >
          Options
        </div>
      </div>

      <div>
        {activeTab === "Analytics" && <AnalyticTab />}
        {activeTab === "Leads" && <Lead />}
        {activeTab === "Sequence" && <Sequence />}
        {activeTab === "Schedule" && <Schedule />}
        {activeTab === "Options" && <Option />}

        {/* Add other components for remaining tabs here */}
      </div>
    </div>
  );
}

export default CampaignsTab;
