import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { FaUpload } from "react-icons/fa";
import CancelHeader from "../CancelHeader/CancelHeader";

function UploadDocument() {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted!");
    
  };
  return (
    <div>
      <div>
        <CancelHeader />
        <div className="text-center mt-4">
          <div>
            <div className="provider-heading fw-medium">
              <span className="text-black">
                <IoMdArrowBack />
              </span>
              Choose another method
            </div>
          </div>
          <div className="container mt-5">
            <div className="text-center mb-4 fs-5 fw-medium">
              Upload CSV File
            </div>
            <div className="form-box">
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="gap-3"
              >
                <div className="form-group fs-2">
                  <FaUpload />
                </div>
                <label htmlFor="csvFile" className="mt-3 fs-6">
                  CLICK TO UPLOAD
                </label>
                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-primary">
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadDocument;
