import React from "react";
import BackHeader from "../BackHeader/BackHeader";
import { FaFileCsv } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import "./UploadLead.css";
import { MdOutlineMail } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import CancelHeader from "../CancelHeader/CancelHeader";

function UpLoadLead() {
    const navigate = useNavigate();
  return (
    <div>
      <CancelHeader />
      <div className="d-flex flex-column justify-content-center align-items-center"   onClick={() => navigate("/uploadleaddocument")}>
        <div className="card w-25 shadow border-0 mt-5" >
          <div className="p-4">
            <div className="d-flex align-items-center gap-4">
              <div className="fs-1 text-info border-right pe-3 me-2">
                <span>
                  <FaFileCsv />
                </span>
              </div>
              <div>
                <div className="text-secondary fw-medium fs-5">Upload</div>
                <div className="fs-3 fw-medium">CSV</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-25 shadow border-0 mt-2">
          <div className="p-4">
            <div className="d-flex align-items-center gap-4">
              <div className="fs-1 text-secondary border-right pe-3 me-2">
                <span>
                  <CiSearch />
                </span>
              </div>
              <div>
                <div className="text-secondary fw-medium fs-5">Use</div>
                <div className="fs-3 fw-medium">Lead Finder</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-25 shadow border-0 mt-2">
          <div className="p-4">
            <div className="d-flex align-items-center gap-4">
              <div className="fs-1 text-info border-right pe-3 me-2">
                <span>
                  <FcGoogle />
                </span>
              </div>
              <div>
                <div className="text-secondary fw-medium fs-5">Enter</div>
                <div className="fs-3 fw-medium">Emails Manually</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-25 shadow border-0 mt-2">
          <div className="p-4">
            <div className="d-flex align-items-center gap-4 ">
              <div className="fs-1 text-info border-right pe-3 me-2 ">
                <span>
                  <MdOutlineMail />
                </span>
              </div>
              <div>
                <div className="text-secondary fw-medium fs-5">Use</div>
                <div className="fs-3 fw-medium">Google Sheets</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpLoadLead;
