import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function CancelHeader() {
    const navigate = useNavigate();
  return (
    <div>
      <header
        className="back-header shadow text-white py-3 px-4 "
        style={{ backgroundColor: "#2a2a2a" }}
      >
        <div className="d-flex justify-content-between">
          <div className="fs-6 fw-medium d-flex align-items-center  text-black">
            <span className=" me-1 text-white">
              <IoMdArrowBack onClick={() => navigate("/Campaignstest")}/>
            </span>
            <div className="mt-1 fs-5 text-white">Back</div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default CancelHeader;
