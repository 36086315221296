import { useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import axios from "axios";
import { Remove_URL, Paused_URL } from "../../firebase/config";
import { ensureValidToken } from "../../auth/authUtils";
import "./OptionMenu.css";

const OptionMenu = ({ selectedEmails = [], setSelectedEmails, fetchData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteSelected = async () => {
    if (selectedEmails?.length === 0) {
      alert("No accounts selected.");
      return;
    }
    const confirmDelete = window.confirm(
      "Are you sure you want to delete selected accounts?"
    );
    if (!confirmDelete) return;

    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        Remove_URL,
        { accountIds: selectedEmails },
        { headers }
      );

      if (response.data.success) {
        alert("Accounts removed successfully.");
        setSelectedEmails([]);
        fetchData();
      } else {
        alert("Failed to delete accounts.");
      }
    } catch (error) {
      console.error("Delete error:", error.message);
      alert("Failed to delete selected accounts. Please try again.");
    }
  };

  const handlePauseSelected = async () => {
    if (selectedEmails?.length === 0) {
      alert("No accounts selected.");
      return;
    }
    const confirmPause = window.confirm(
      "Are you sure you want to pause selected accounts?"
    );
    if (!confirmPause) return;

    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        Paused_URL,
        { accountIds: selectedEmails },
        { headers }
      );

      if (response.data.success) {
        alert("Accounts paused successfully.");
        setSelectedEmails([]);
        fetchData();
      } else {
        alert("Failed to pause accounts.");
      }
    } catch (error) {
      console.error("Pause error:", error.message);
      alert("Failed to pause selected accounts. Please try again.");
    }
  };

  return (
    <div className="mt-3">
      <button onClick={toggleDropdown} className="option-menu-button">
        <HiDotsHorizontal className="text-gray-500" />
      </button>

      <div className={`option-menu-dropdown  ${isOpen ? "show" : ""}`}>
        <ul className="py-1 ">
          <li className="option-menu-item" onClick={handlePauseSelected}>
            Pause Selected Accounts
          </li>
          <li className="option-menu-item" onClick={handleDeleteSelected}>
            Delete Selected Accounts
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OptionMenu;
