import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import "./AnyProvider.css";
import { GrDocumentCsv } from "react-icons/gr";
import { MdOutlineMailOutline } from "react-icons/md";
import BackHeader from "../BackHeader/BackHeader";
import { useNavigate } from "react-router-dom";

function AnyProvider() {
  const navigate = useNavigate();
  return (
    <div>
      <BackHeader />
      <div className=" text-center mt-4">
        <div>
          <span>
            <IoMdArrowBack onClick={() => navigate("/addacounts")} />
          </span>
          <div className="provider-heading fw-medium">
            Select another provider
          </div>
         
        </div>
      </div>
      <div className="d-flex flex-column align-items-center mt-3">
        <div
          className="card shadow border-0 mt-3 custom-card-width"
          onClick={() => navigate("/Upload")}
        >
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="fs-1 me-5">
                <i className="csv">
                  <GrDocumentCsv />
                </i>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="fs-6 fw-medium text-secondary">
                  Any Provider
                </div>
                <div className="fs-6 fw-bold">Bulk Import from CSV</div>
              </div>
            </div>
          </div>
        </div>

        <div className="card custom-card-width shadow border-0 mt-3">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="fs-1 me-5">
                <i className=" ">
                  <MdOutlineMailOutline />
                </i>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="fs-6 fw-medium text-secondary">
                  Any Provider
                </div>
                <div className="fs-6 fw-bold">Single Account</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnyProvider;
